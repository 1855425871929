<template>
  <component
    :is="tag"
    v-on="$listeners"
  >
    <b-avatar
      size="42"
      :src="user.profile.image"
      :badge="isChatContact"
      class="badge-minimal"
      :badge-variant="resolveAvatarBadgeVariant(user.profile.onlineStatus)"
      variant="transparent"
    />
    <div class="chat-info flex-grow-1">
      <h5 class="mb-0">
        {{ user.userName || $t('Anonymous') }}
        <img v-if="user.isAssistant" :src="verifiedIcon" width="16" style="border: none; margin: -4px 0 0 4px; " />
      </h5>
      <p class="card-text text-truncate">
        {{ isChatContact ? user.lastMessage.message : '' }}
      </p>
    </div>
    <div
      v-if="isChatContact"
      class="chat-meta text-nowrap"
    >
      <small class="float-right mb-25 chat-time">
        {{ formatDateToMonthShort(user.lastMessage.dateSent, { hour: 'numeric', minute: 'numeric' }) }}
      </small>
      <b-badge
        v-if="user.unSeenMsgCount"
        pill
        variant="primary"
        class="float-right"
      >
        {{ user.unSeenMsgCount }}
      </b-badge>
    </div>
  </component>
</template>

<script>
import { BAvatar, BBadge } from 'bootstrap-vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import useChat from './useChat'

export default {
  components: {
    BAvatar,
    BBadge,
  },
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    user: {
      type: Object,
      required: true,
    },
    isChatContact: {
      type: Boolean,
      dedfault: false,
    },
  },
  data() {
    return {
      verifiedIcon: require('@/assets/images/icons/verified.png'),
    }
  },
  setup() {
    const { resolveAvatarBadgeVariant } = useChat()
    return { formatDateToMonthShort, resolveAvatarBadgeVariant }
  },
}
</script>
