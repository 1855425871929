<template>
  <div class="chats">
    <div v-if="!allMessagesLoaded" class="d-flex justify-content-center">
      <b-button pill variant="outline-secondary" size="sm" @click="$emit('loadMore')">
        {{ $t('Load more') }}
      </b-button>
    </div>
    <div
      v-for="(msgGrp, index) in formattedChatData.formattedChatLog"
      :key="msgGrp.fromId + String(index)"
      class="chat"
    >
      <h5 v-if="isShowDate(msgGrp.messages[0].dateSent, index)" class="chat-date">
        {{ getDate(msgGrp.messages[0].dateSent) }}
      </h5>
      <div class="chat-container" :class="{'chat-left': msgGrp.fromId === formattedChatData.contact.id}">
        <div v-if="msgGrp.fromId === formattedChatData.contact.id" class="chat-avatar">
          <b-avatar
              size="36"
              class="avatar-border-2 box-shadow-1"
              variant="transparent"
              :src="msgGrp.fromId === formattedChatData.contact.id ? formattedChatData.contact.avatar : profileUserAvatar"
          />
        </div>
        <div class="chat-body">
          <div
              v-for="(msgData, i) in msgGrp.messages"
              :key="i"
              class="chat-content"
              :class="{'bg-none': msgData.type === 'CHAT_GIFT', 'bs-none': msgData.type === 'CHAT_GIFT'}"
          >
            <p v-if="msgData.type === 'CHAT_MSG'" class="chat-message">{{ msgData.message }}</p>
            <b-img v-if="msgData.type === 'CHAT_GIFT' && msgData.gift"
                   :src="getImgGift(msgData.gift.image)" width="42" />
            <div class="chat-footer">
              {{ getTime(msgData.dateSent) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import { BAvatar, BButton, BImg } from 'bootstrap-vue'
import moment from 'moment'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import i18n from '../../libs/i18n'
import { isToday } from "@core/utils/utils";

export default {
  components: {
    BAvatar,
    BButton,
    BImg,
  },
  props: {
    allMessagesLoaded: {
      type: Boolean,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    chatData: {
      type: Object,
      required: true,
    },
    profileUserAvatar: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18nUtils()

    const formattedChatData = computed(() => {
      const contact = {
        id: props.chatData.contact.id,
        avatar: props.chatData.contact.profile.image,
      }

      let chatLog = []
      if (props.chatData.chat) {
        chatLog = props.chatData.chat
      }

      const formattedChatLog = []
      let chatMessageSenderId = chatLog[0] ? chatLog[0].fromId : undefined
      let msgGroup = {
        fromId: chatMessageSenderId,
        messages: [],
      }

      let msgDate = getDate(chatLog.length > 0 ? chatLog[0].dateSent : null)

      chatLog.forEach((msg, index) => {
        if (chatMessageSenderId === msg.fromId && getDate(msg.dateSent) === msgDate) {
          msgGroup.messages.push({
            message: msg.message,
            dateSent: msg.dateSent,
            type: msg.type,
            gift: msg.gift,
          })
        } else {
          msgDate = getDate(msg.dateSent)
          chatMessageSenderId = msg.fromId
          formattedChatLog.push(msgGroup)
          msgGroup = {
            fromId: msg.fromId,
            messages: [{
              message: msg.message,
              dateSent: msg.dateSent,
              type: msg.type,
              gift: msg.gift,
            }],
          }
        }
        if (index === chatLog.length - 1) formattedChatLog.push(msgGroup)
      })

      return {
        formattedChatLog,
        contact,
        profileUserAvatar: props.profileUserAvatar,
      }
    })

    let shownDates = []
    const isShowDate = (date, index) => {
      if (index === 0) {
        shownDates = []
      }
      const dateStr = moment(date).format('DD.MM.YYYY')
      if (shownDates.includes(dateStr)) return false
      shownDates.push(dateStr)
      return true
    }

    const getImgGift = name => {
      return require('@/assets/images/gifts/' + name)
    }

    const getTime = date => {
      return moment(date).format('HH:mm')
    }

    const getDate = date => {
      if (!date) return ''
      if (isToday(new Date(date))) return t('Today')
      return moment(date).locale(i18n.locale).format('DD.MM.YYYY dddd')
    }

    return {
      getImgGift,
      getDate,
      getTime,
      formattedChatData,
      isShowDate,
    }
  },
}
</script>

<style lang="scss" scoped>
.chat-message {
  color: #fff;
}
.chat-left {
  .chat-message {
    color: #000;
  }
}
</style>

