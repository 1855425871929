<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <b-overlay :show="loading || socketConnecting" no-center variant="transparent" rounded="sm">
      <template #overlay>
        <loading-spinner/>
      </template>
    </b-overlay>
    <div
        class="body-content-overlay"
        :class="{'show': shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar}"
        @click="mqShallShowLeftSidebar=shallShowActiveChatContactSidebar=shallShowUserProfileSidebar=false"
    />

    <portal to="message-information">
      <div class="message-information">
        <div class="remaining-message">
          <div class="chat-icon">
            <b-img :src="iconChat3" width="28" height="28" />
          </div>
          <div class="remaining-message-text">
            <div v-if="!messageCountLoading">
              <p v-if="messageTimeLeft <= 0 && messageCount <= 0">
                {{ $t('You do not have the right to message, you can buy the package you want to enjoy the chat.') }}
              </p>
              <p v-if="!messageCountLoading && messageTimeLeft > 0"
                 v-html="$t('You have unlimited messages. Enjoy messaging for next {time}', {time: messageTimeLeftForHuman})" />
              <div v-else>
                <p v-if="!messageCountLoading && messageCount > 0"
                   v-html="$t('You’ve {count} messages. Enjoy messaging.', {count: messageCount})"/>
              </div>
            </div>
          </div>
        </div>
        <div class="market-button">
          <router-link :to="{name: 'market'}">
            <b-img :src="iconDiamonds1" width="20" height="20" />
            {{ $t('Market') }}
          </router-link>
        </div>
      </div>
    </portal>

    <!-- Main Area -->
    <section class="chat-app-window">

      <!-- Start Chat Logo -->
      <div
          v-if="!activeChat.contact"
          class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
              icon="MessageSquareIcon"
              size="56"
          />
        </div>
        <h4
            class="sidebar-toggle start-chat-text"
            @click="startConversation"
        >
          {{ $t('Start chat') }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
          v-else
          class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">

              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                    icon="MenuIcon"
                    class="cursor-pointer"
                    size="21"
                    @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <b-avatar
                  size="36"
                  :src="activeChat.contact.profile.image"
                  class="mr-1 cursor-pointer badge-minimal"
                  badge
                  :badge-variant="resolveAvatarBadgeVariant(activeChat.contact.profile.onlineStatus)"
                  @click.native="shallShowActiveChatContactSidebar=true"
              />
              <router-link :to="{name: 'users.show', params: {id: activeChat.contact.id}}">
                <h6 class="mb-0 fs-16 fw-600">
                  {{ activeChat.contact.userName }}
                </h6>
              </router-link>
            </div>

            <!-- Contact Actions -->
            <div
                v-if="activeChat && activeChat.contact && !activeChat.contact.isAssistant"
                class="d-flex align-items-center">
              <b-img :src="iconPhone" @click="call(activeChat.contact)"
                     class="cursor-pointer d-sm-block d-none mr-1"
                     style="background: #FFFFFF; border: 1px solid #E7E7E7; border-radius: 50%; padding: 6px;"/>
              <b-img v-if="activeChat.extended && activeChat.extended.profile.cameraOn" :src="iconVideoCall"
                     @click="videoCall(activeChat.contact)"
                     class="cursor-pointer d-sm-block d-none mr-1"
                     style="background: #FFFFFF; border: 1px solid #E7E7E7; border-radius: 50%; padding: 6px;"/>
              <div class="dropdown">
                <b-dropdown
                    v-if="!activeChat.contact.isAssistant"
                    variant="link"
                    no-caret
                    toggle-class="p-0"
                    right
                >
                  <template #button-content>
                    <b-img :src="iconMoreHorizontal" class="cursor-pointer" width="18" />
                  </template>
                  <b-dropdown-item :to="{name: 'users.show', params: {id: activeChat.contact.id}}">
                    {{ $t('View profile') }}
                  </b-dropdown-item>
                  <b-dropdown-item v-if="!activeChat.contact.blockedByUser" @click="blockUser(activeChat.contact.id)">
                    {{ $t('Block user') }}
                  </b-dropdown-item>
                  <b-dropdown-item v-else @click="unBlockUser(activeChat.contact.id)">
                    {{ $t('Unblock') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="modalReportUser = true">
                    {{ $t('Report') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
            ref="refChatLogPS"
            class="user-chats scroll-area"
        >
          <chat-log
              @loadMore="loadMore"
              :all-messages-loaded="allMessagesLoaded"
              :chat-data="activeChat"
              :profile-user-avatar="profileUserDataMinimal.profile.image"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <div>
          <b-form
              class="chat-app-form"
              @submit.prevent="socketConnected && (messageCount > 0 || messageTimeLeft > 0) ? send() : null"
          >
            <b-input-group class="input-group-merge form-send-message"
                           @click="messageCount <= 0 && messageTimeLeft <= 0 ? goMarket() : null">
              <template #prepend>
                <b-input-group-text style="border-top-left-radius: 38px; border-bottom-left-radius: 38px; padding: 2px 12px;"
                                    class="cursor-pointer bg-light-gray"
                                    @click="chooseGiftModal = true"
                                    :id="socketConnected && (messageCount > 0 || messageTimeLeft > 0) ? 'gifts-popover' : 'no-popover'">
                  <div style="padding: 3px; background-color: #fff; border-radius: 50%; width: 30px; height: 30px;">
                    <b-img src="@/assets/images/gifts/gift.svg" width="18"/>
                  </div>
                </b-input-group-text>
                <!--                <b-popover target="gifts-popover" placement="topright" custom-class="gifts-popover"-->
                <!--                           :show.sync="showGifts">-->
                <!--                  <div v-for="gift in gifts" :key="gift.productId" class="gift">-->
                <!--                    <b-img widtg="64" :alt="gift.label"-->
                <!--                           :src="getImgGift(gift.image)" class="gift-image" @click="showGiftModal(gift)"/>-->
                <!--                    <p v-if="userGender === 'male'">-->
                <!--                      {{ gift.credits }} Elmas-->
                <!--                    </p>-->
                <!--                  </div>-->
                <!--                </b-popover>-->
              </template>
              <b-form-input
                  class="bg-light-gray"
                  :disabled="!socketConnected || (messageCount <= 0 && messageTimeLeft <= 0)"
                  v-model="chatInputMessage"
                  :placeholder="messageInputText"
              />
              <template #append @click="socketConnected && (messageCount > 0 || messageTimeLeft > 0) ? send() : null">
                <b-input-group-text style="border-top-right-radius: 38px; border-bottom-right-radius: 38px; padding: 2px 12px;"
                                    @click="socketConnected && (messageCount > 0 || messageTimeLeft > 0) ? send() : null"
                                    class="cursor-pointer bg-light-gray">
                  <div style="padding: 4px; background-color: #fff; border-radius: 50%; width: 30px; height: 30px;">
                    <b-img src="@/assets/images/icons/send.svg" width="18"/>
                  </div>
                </b-input-group-text>
              </template>
            </b-input-group>
          </b-form>
        </div>
      </div>
    </section>

    <!-- Active Chat Contact Details Sidebar -->
    <chat-active-chat-content-details-sidedbar
        :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
        :contact="activeChat.extended || {}"
    />

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
          :chats-contacts="chatsContacts"
          :active-chat-contact-id="activeChat.contact ? activeChat.contact.id : null"
          :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
          :profile-user-data="profileUserData"
          :profile-user-minimal-data="profileUserDataMinimal"
          :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
          @show-user-profile="showUserProfileSidebar"
          @open-chat="openChatOfContact"
      />
    </portal>

    <b-modal v-if="selectedGift" v-model="giftModal" centered hide-header-close hide-footer size="sm"
             :title="activeChat ? activeChat.contact.userName + ' için hediye' : ''">
      <div class="gifts-modal">
        <h4>{{ selectedGift.label }}</h4>
        <b-img :src="getImgGift(selectedGift.image)" width="128" :alt="selectedGift.label" />
        <h4 v-if="userGender === 'male'">
          {{ selectedGift.credits }} Elmas
        </h4>
        <b-button variant="primary" block @click="sendGift(selectedGift)" :disabled="loading || socketConnecting">
          {{ $t('Send') }}
        </b-button>
        <b-button variant="outline-dark" size="sm" @click="hideGiftModal(true)" :disabled="loading || socketConnecting">
          {{ $t('Choose Another Gift')}}
        </b-button>
      </div>
    </b-modal>

    <b-modal v-if="activeChat.contact" v-model="modalReportUser"
             :title="$t('Report {name}', { name: activeChat.contact.userName })"
             centered hide-header-close hide-footer>
      <div>
        <validation-observer
            v-if="reportReasonTypes.length"
            ref="reportForm"
            #default="{invalid}"
        >
          <b-form
              class="mt-2"
              @submit.prevent
          >

            <b-form-group
                label-for="reason"
                label="Şikayet nedeni"
            >
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Report reason')"
                  vid="reason"
                  rules="required"
              >
                <v-select
                    v-model="report.reason"
                    :clearable="false"
                    :disabled="loading"
                    :label="$t('Report reason')"
                    :options="reportReasonTypes"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

              <b-form-group
                  label-for="reportMessage"
                  :label="$t('Your message')"
              >
                <validation-provider
                    #default="{ errors }"
                    :name="$t('Your message')"
                    vid="description"
                    rules="required"
                >
                  <b-form-textarea
                      id="description"
                      :disabled="loading"
                      v-model="report.description"
                      :placeholder="$t('Your message')"
                      rows="3"
                      max-rows="6"
                  ></b-form-textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-form-group>

            <b-button
                variant="primary"
                class="mt-2 px-4"
                :disabled="loading"
                @click="reportUser"
            >
              <b-spinner v-if="loading" small></b-spinner>
              {{ $t('Report') }}
            </b-button>
          </b-form>
        </validation-observer>
      </div>
    </b-modal>

    <b-modal v-model="chooseGiftModal" body-class="choose-gift-modal-body" scrollable hide-footer size="lg">
      <div class="choose-gift-modal" >
        <div v-for="gift in gifts" :key="gift.productId" class="gift">
          <b-img width="64" :alt="gift.label"
                 :src="getImgGift(gift.image)" class="gift-image" @click="showGiftModal(gift)"/>
          <p v-if="userGender === 'male'">
            {{ numberFormat(gift.credits) }}
            <b-img :src="iconDiamonds1" width="16" height="16" style="margin-top: -2px" />
          </p>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  ref, onUnmounted, onMounted, nextTick, computed, watch, onBeforeUnmount,
} from '@vue/composition-api'
import {
  BAvatar, BDropdown, BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea, BInputGroup, BButton, BOverlay,
  BInputGroupText, BImg, BPopover, BSpinner,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import ChatLeftSidebar from './ChatLeftSidebar.vue'
import chatStoreModule from './chatStoreModule'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import ChatLog from './ChatLog.vue'
import useChat from './useChat'
import useJwt from '@/auth/jwt/useJwt'
import _ from 'lodash'
import {gtmTrackNewEvent, showNotify, throwDefaultError, numberFormat} from "@/helpers/helpers";
import vSelect from 'vue-select'
import { useUtils as useI18nUtils } from '@core/libs/i18n'

export default {
  name: 'chat',
  components: {

    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BButton,
    BOverlay,
    BInputGroupText,
    BImg,
    BPopover,
    BSpinner,

    // 3rd Party
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,

    ValidationObserver,
    ValidationProvider,
    vSelect,
  },
  setup() {
    // Use toast
    const toast = useToast()

    const { t } = useI18nUtils()

    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    const iconDiamonds1 = require('@/assets/images/icons/diamonds-1.svg')
    const iconChat3 = require('@/assets/images/icons/chat-3.png')
    const iconPhone = require('@/assets/images/icons/phone-black.svg')
    const iconVideoCall = require('@/assets/images/icons/video-call-black.svg')
    const iconMoreHorizontal = require('@/assets/images/icons/more-horizontal-black.svg')

    const allMessagesLoaded = ref(true)
    const chatMessagesOffset = ref(0)
    const chatMessagesLimit = ref(200)
    const chooseGiftModal = ref(false)
    const giftModal = ref(false)
    const gifts = ref([])
    const loading = ref(false)
    const messageCount = ref(0)
    const messageTimeLeft = ref(0)
    const messageTimeLeftInterval = ref(null)
    const messageCountLoading = ref(false)
    const selectedGift = ref(null)
    const showGifts = ref(false)
    const modalReportUser = ref(false)
    const report = ref({
      reason: '',
      description: '',
    })
    const reportForm = ref(null)

    // $router and $route
    const $router = useRouter().router
    const $route = useRouter().route

    // Kalan mesaj süresi
    const messageTimeLeftForHuman = computed(() => {
      let timeLeft = messageTimeLeft.value

      if (timeLeft <= 0) {
        return 0
      }

      let result = ''
      let totalSeconds = timeLeft / 1000;

      const days = Math.floor(totalSeconds / (60 * 60 * 24))
      totalSeconds -= days * (60 * 60 * 24)

      const hours = Math.floor(totalSeconds / (60 * 60))
      totalSeconds -= hours * (60 * 60)

      const minutes = Math.floor(totalSeconds / (60))
      totalSeconds -= minutes * 60

      const seconds = Math.floor(totalSeconds % 60)

      if (days > 0) {
        result += days + ` ${t('days')}, `
      }

      if (hours > 0) {
        result += hours + ` ${t('hours')}, `
      }

      if (minutes > 0) {
        result += minutes + ` ${t('minutes')}, `
      }

      // if (seconds > 0) {
      //   result += seconds + ` ${t('seconds')}, `
      // }

      return _.trimEnd(result, ', ')
    })
    const reportReasonTypes = computed(() => {
      return store.getters['app/reportReasonTypes']
    })

    const chatsContacts = ref([])
    const chatsContactsLoaded = ref(false)

    const routeParamsUserId = $route.value?.params?.userId
    watch(chatsContactsLoaded, value => {
      // route'tan userId parametresi geldiyse user bilgilerini çek veya mevcut chat varsa onu aktif et
      if (value && $route && routeParamsUserId) {
        const _existContact = chatsContacts.value.find(c => c.id === routeParamsUserId)
        if (_existContact) {
          openChatOfContact(routeParamsUserId)
        } else {
          getUser(routeParamsUserId)
        }
      }
    })

    // Websocket işlemleri
    const socket = computed(() => store.getters['socket/chatConnection'])
    const socketConnecting = computed({
      get: () => store.getters['socket/connecting'],
      set: (value) => store.commit('socket/updateConnecting', value),
    });
    const socketConnected = computed({
      get: () => store.getters['socket/connected'],
      set: (value) => store.commit('socket/updateConnected', value),
    });
    // const openSocket = () => {
    //   socket = io(process.env.VUE_APP_CHAT_SOCKET_URL,
    //       {
    //         reconnection: true,
    //         query: {
    //           uuid: store.getters['auth/userId'],
    //           'x-bpsh-connect-api-key': process.env.VUE_APP_API_KEY,
    //           'x-bpsh-connect-token': store.getters['auth/token']
    //         },
    //         transports: ['websocket', 'polling', 'flashsocket']
    //       })
    // }
    // openSocket()

    const messageInputText = computed(() => {
      return (messageCount.value <= 0 && messageTimeLeft.value <= 0)
          ? t('You do not have the right to message. Click to buy.')
          : (socketConnected.value ? t('Send message') : t('Connecting to the system. Please wait.'))
    })

    // Komponent unmount olduğunda socketi kapat
    // onBeforeUnmount(() => {
    //   if (socket.value) {
    //     console.log(' socket.disconnect()',  socket.value.disconnect())
    //   }
    // })

    if (socket.value) {
      socket.value.on('reconnect_attempt', () => {})

      socket.value.on('reconnect', attemptNumber => {})

      // Mesaj geldi
      socket.value.on('receive', (chatMsgDTO) => {
        // type: string --> chat mesajı için 'CHAT_MSG', hediye için 'CHAT_GIFT'
        // content: string --> Mesaj içeriği
        // from: string ---> Gönderen kişinin uuid'si
        // to: string ---> Mesajın gönderileceği kişinin uuid'si

        const _message = {
          fromId: chatMsgDTO.from,
          toId: chatMsgDTO.to,
          message: chatMsgDTO.content,
          dateSent: new Date().getTime(),
          dateSeen :null,
          type: chatMsgDTO.type,
          gift: null
        }

        // Aktif chat'e geldiyse ona ekle
        try {
          if (activeChat.value && activeChat.value.contact.id === chatMsgDTO.from) {
            activeChat.value.chat.push(_message)

            // Mesajları okundu olarak işaretle
            useJwt.chatMarkSeen2(chatMsgDTO.from)
                .then(response => {})
          }
        } catch (err) {

        }

        // contact'ları yeniden çek
        fetchChatAndContacts(true)
      })

      // Mesaj gönderme başarısız
      socket.value.on('rejected', rspRejectedChatMsgDTO => {
        // rspRejectedChatMsgDTO:
        //     message: chatMsgDTO
        // reason: string
        //
        // reason listesi {
        //   "Cant find user",
        //       "Selected gift is not found",
        //       "Cant send gift to yourself",
        //       "Cant send gift to a blocked user",
        //       "Selected product is not a gift",
        //       "insufficient credits"
        // }

        // Aktif mesajlardan sil
        activeChat.value.chat = activeChat.value.chat.filter(m => !(m.message === rspRejectedChatMsgDTO.message.content && rspRejectedChatMsgDTO.message.type === 'CHAT_MSG'))

        // Aktif mesajlardan sil
        // activeChat.value.chat = activeChat.value.chat.filter(m => !(m.message === rspRejectedChatMsgDTO.message.content && rspRejectedChatMsgDTO.message.type === 'CHAT_MSG'))

        toast({
          component: ToastificationContent,
          props: {
            title: rspRejectedChatMsgDTO.reason,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            hideClose: true
          },
        })
        giftModal.value = false

        // Chatcontacts'taki son mesajı sil
        // Tip mesaj ise son mesjaı değişkene ata
        if (rspRejectedChatMsgDTO.message.type === 'CHAT_MSG') {
          chatInputMessage.value = rspRejectedChatMsgDTO.message.content
        }

        // Chatcontacts'taki son mesajı sil

        loading.value = false

        nextTick(() => { scrollToBottomInChatLog() })
      })

      // Disconnect oldu
      socket.value.on("disconnect", reason => {
        socketConnected.value = false
        if (reason === 'io server disconnect') {
          // the disconnection was initiated by the server, you need to reconnect manually
          socket.value.connect()
        }
      })

      // Hediye gönderildi
      socket.value.on('giftSent', rspChatMsgDTO => {
        // giftDTO:
        //     createdAt: date (oluşturulma tarihi)
        // productId: string (ör: "com.arasana.gifts.v1.tea")
        // credits: integer (hediyenin kredisi)
        // targetUser: user (alıcı kişi bilgileri)
        // label: string (gönderen kişinin profil diline göre localized hediye ismi)
        // image: string (hediye imaj ismi)

        const _message = {
          dateSent: new Date().getTime(),
          dateSeen : null,
          message: rspChatMsgDTO.gift.label,
          ...rspChatMsgDTO,
        }
        addMessageToChat(_message)

        getMe()

        gtmTrackNewEvent('giftsent', {
          category: 'Chat',
          action: 'GiftSent',
          label: activeChat.value.contact.id
        })

        loading.value = false
        showGifts.value = false
        selectedGift.value = null
      })

      // Hediye alındı
      socket.value.on('giftReceived', (rspChatMsgDTO) => {
        // rspChatMsgDTO:
        //     type: 'CHAT_GIFT'
        // content: productId
        // from: sender uuid
        // to: receiver uuid
        // gift: giftDTO (özellikle ui güncellemek üzere backward compatible gönderilen hediye bilgisi)

        const _message = {
          fromId: rspChatMsgDTO.from,
          toId: rspChatMsgDTO.to,
          message: rspChatMsgDTO.content,
          dateSent: new Date().getTime(),
          dateSeen :null,
          type: rspChatMsgDTO.type,
          gift: rspChatMsgDTO.gift
        }

        // Aktif chat'e geldiyse ona ekle
        try {
          if (activeChat.value && activeChat.value.contact.id === rspChatMsgDTO.from) {
            activeChat.value.chat.push(_message)

            // Mesajları okundu olarak işaretle
            useJwt.chatMarkSeen(rspChatMsgDTO.from)
                .then(response => {})
          }
        } catch (err) {

        }

        // contact'ları yeniden çek
        fetchChatAndContacts(true)
      })
    }

    // Computeds
    const profileUserData = computed(() => store.getters['auth/user'])
    const profileUserDataMinimal = computed(() => store.getters['auth/user'])
    const userGender = computed(() => store.getters['auth/userGender'])

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
    })

    onMounted(() => {
      if (window.innerWidth <= 770) {
        startConversation()
      }
    })

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      if (refChatLogPS.value) {
        const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
        setTimeout(() => {
          scrollEl.scrollTop = scrollEl.scrollHeight
        }, 100)
      }
    }

    // Mesaj gönderme ortak fonksiyonu (normal mesaj ve hediye için)
    const sendMessage = (payload, addMessages = true, gift = null) => {
      // socket.value.emit('send', JSON.stringify(payload))
      socket.value.emit('send', payload)

      if (addMessages) {
        const _message = {
          fromId: payload.from,
          toId: payload.to,
          message: payload.content,
          dateSent: new Date().getTime(),
          dateSeen : null,
          type: payload.type,
          gift
        }
        addMessageToChat(_message)
      }
    }
    const addMessageToChat = (message) => {
      // Aktif chat'e ekle
      activeChat.value.chat.push(message)

      // Contact'ları yeniden çek
      fetchChatAndContacts(false)

      chatInputMessage.value = ''

      // Kredi miktarını yeniden çek
      setTimeout(() => {
        getMe()
      }, 11)

      nextTick(() => { scrollToBottomInChatLog() })
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const fetchChatAndContacts = (showLoading = false) => {
      if (showLoading) {
        loading.value = true
      }
      useJwt.getChatUsers2(0, 100)
          .then(response => {
            chatsContacts.value = response.data.body || []
            chatsContactsLoaded.value = true
            nextTick(() => { scrollToBottomInChatLog() })
          })
          .catch(error => {})
          .finally(() => {
            loading.value = false
          })
    }
    fetchChatAndContacts(true)

    const getMe = (showLoading = false, showLoadingMessage = false) => {
      if (showLoading) {
        loading.value = true
      }
      if (showLoadingMessage) {
        messageCountLoading.value = true
      }
      useJwt.getMe()
          .then(response => {
            messageCount.value = response.data.body.chatAccount.msgCount
            messageTimeLeft.value = response.data.body.chatAccount.timeLeft

            store.dispatch('auth/updateUser', response.data.body)

            if (messageTimeLeft.value > 0) {
              if (messageTimeLeftInterval.value) {
                clearInterval(messageTimeLeftInterval.value)
                messageTimeLeftInterval.value = null
              }
              messageTimeLeftInterval.value = setInterval(() => {
                messageTimeLeft.value -= 1000
              }, 1000)
            }
          })
          .catch(error => {})
          .finally(() => {
            loading.value = false
            messageCountLoading.value = false
          })
    }
    getMe(true, true)

    const getGifts = (showLoading = false) => {
      if (showLoading) {
        loading.value = true
      }
      useJwt.getGifts()
          .then(response => {
            gifts.value = response.data.body
          })
          .catch(error => {})
          .finally(() => {
            loading.value = false
          })
    }
    getGifts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = userId => {
      if (activeChat.value && activeChat.value?.contact?.id === userId) {
        return
      }
      loading.value = true

      // Reset send message input value
      chatInputMessage.value = ''

      loadMessages(userId)

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }
    const loadMessages = (userId, clear = true) => {
      if (clear) {
        chatMessagesOffset.value = 0
        allMessagesLoaded.value = true
      } else {
        loading.value = true
      }

      useJwt.getChat2(userId, chatMessagesOffset.value, chatMessagesLimit.value)
          .then(response => {

            const contact = chatsContacts.value.find(c => c.id === userId)
            if (contact) {
              contact.unSeenMsgCount = 0
            }

            if (clear) {
              activeChat.value = {
                contact,
                chat: response.data.body.result
              }
            } else {
              if (activeChat.value) {
                activeChat.value.chat.unshift(...response.data.body.result)
              }
            }

            getUser(contact.id)

            chatMessagesOffset.value = response.data.body.offset + response.data.body.limit

            if (chatMessagesOffset.value >= response.data.body.totalCount) {
              allMessagesLoaded.value = true
            } else {
              allMessagesLoaded.value = false
            }

            if (clear) {
              nextTick(() => {
                scrollToBottomInChatLog()
              })
            }

            // Mesajları okundu olarak işaretle
            useJwt.chatMarkSeen2(userId)
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => {
            loading.value = false
          })
    }

    // Daha fazla mesaj yükle
    const loadMore = () => {
      if (activeChat.value) {
        loadMessages(activeChat.value.contact.id, false)
      }
    }

    const send = () => {
      if (!chatInputMessage.value) return

      const payload = {
        from: profileUserDataMinimal.value.id,
        to: activeChat.value.contact.id,
        type: 'CHAT_MSG',
        content: chatInputMessage.value,
      }

      gtmTrackNewEvent('chatsent', {
        category: 'Chat',
        action: 'ChatSent',
        label: activeChat.value.contact.id
      })

      sendMessage(payload)
    }

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      shallShowUserProfileSidebar.value = true
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    // Gift
    const openGifts = () => {
      showGifts.value = !showGifts.value
    }
    const showGiftModal = gift => {
      selectedGift.value = gift
      showGifts.value = false
      chooseGiftModal.value = false
      giftModal.value = true

      gtmTrackNewEvent('giftclick', {
        category: 'Chat',
        action: 'Giftclick',
        label: activeChat.value.contact.id
      })
    }
    const hideGiftModal = (showChooseAnotherGift = false) => {
      selectedGift.value = null
      giftModal.value = false
      showGifts.value = true

      if (showChooseAnotherGift) chooseGiftModal.value = true
    }

    // Hediye gönder
    const sendGift = gift => {
      if (!gift) return

      loading.value = true

      const payload = {
        from: profileUserDataMinimal.value.id,
        to: activeChat.value.contact.id,
        type: 'CHAT_GIFT',
        content: gift.productId,
      }

      sendMessage(payload, false, gift)
      hideGiftModal()
    }

    // Gift görselini getir
    const getImgGift = name => {
      return require('@/assets/images/gifts/' + name)
    }

    // Market sayfasına yönlendir
    const goMarket = () => {
      $router.push({name: 'market'})
    }

    // Kullanıcı bilgilerini çek
    const getUser = userId => {
      loading.value = true

      useJwt.getUser(userId)
          .then(response => {
            const user = response.data.body
            const contact = chatsContacts.value.find(c => c.id === user.id)
            if (!contact) {
              const _contact = {
                id: user.id,
                lastMessage: {
                  dateSeen: null,
                  dateSent: new Date().getTime(),
                  fromId: user.id,
                  toId: profileUserDataMinimal.value.id,
                  message: '',
                  type: 'CHAT_MSG'
                },
                profile: user.profile,
                unSeenMsgCount: 0,
                userName: user.userName
              }
              chatsContacts.value.unshift(_contact)

              openChatOfContact(user.id)
            } else {
              activeChat.value = {
                ...activeChat.value,
                extended: { ...user }
              }
            }
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => { loading.value = false })
    }

    const blockUser = id => {
      loading.value = true
      useJwt.blockUser(id)
          .then(response => {
            showNotify(toast, t('User blocking successful'), 'success')
            activeChat.value = {}
            fetchChatAndContacts(true)
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => { loading.value = false })
    }
    const unBlockUser = id => {
      loading.value = true
      useJwt.unBlockUser(id)
          .then(response => {
            showNotify(toast, t('Unblocking user successful'), 'success')
            fetchChatAndContacts(true)
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => { loading.value = false })
    }
    const reportUser = () => {
      reportForm.value.validate().then(success => {
        if (success) {
          loading.value = true
          const data = {
            reportee: store.getters['auth/userName'],
            reporteeEmail: 'email@email.com',
            reported: activeChat.value.contact.userName,
            reason: report.value.reason,
            description: report.value.description,
          }
          useJwt.reportUser(data)
              .then(response => {
                showNotify(toast, t('Reporting user successful'), 'success')
                modalReportUser.value = false
                report.value = {
                  reason: '',
                  description: '',
                }
              })
              .catch(error => {
                throwDefaultError(toast, error)
              })
              .finally(() => {
                loading.value = false
              })
        }
      })
    }

    const call = contact => {
      loading.value = true
      useJwt.getUser(contact.id)
          .then(response => {
            const user = response.data.body
            store.dispatch('app/setModalCallUser', user)
            store.dispatch('app/setModalCall', true)
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => { loading.value = false })

      // if (contact.profile?.callType == 2) {
      //   // Kullanıcı app üzerinden arama kabul ettiği için agora üzerinden arama yap
      //   // store.dispatch('app/setModalDownloadMobileApp', true)
      //   store.dispatch('agora/setShowAgoraCall', true)
      //   store.dispatch('agora/setCallUser', contact)
      // } else {
      //   if (!store.getters['auth/userPhoneNumber']) {
      //     store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //     store.dispatch('app/setModalAddPhoneNumber', true)
      //     store.dispatch('app/setVerificationType', 'phone')
      //   } else {
      //     loading.value = true
      //     useJwt.callUser(contact.id)
      //         .then(response => {
      //           showNotify(toast, 'Calling successful', 'success')
      //         })
      //         .catch(error => {
      //           const { response } = error
      //           throwDefaultError(this.$toast, error)
      //           if (response && response.data?.header?.error?.errorCode == 215) {
      //             store.dispatch('app/setModalAddPhoneNumberShowDiamond', false)
      //             store.dispatch('app/setModalAddPhoneNumber', true)
      //             store.dispatch('app/setVerificationType', 'phone')
      //           }
      //         })
      //         .finally(() => { loading.value = false })
      //   }
      // }
    }

    const videoCall = contact => {
      loading.value = true
      useJwt.getUser(contact.id)
          .then(response => {
            const user = response.data.body
            store.dispatch('app/setModalCallUser', user)
            store.dispatch('app/setModalCall', true)

            store.dispatch('app/setModalCallType', 'video')
            store.dispatch('app/setModalCall', false)
            setTimeout(() => {
              store.dispatch('app/setModalCallUser', user)
              store.dispatch('app/setModalCall', true)
            }, 33)
          })
          .catch(error => {
            throwDefaultError(toast, error)
          })
          .finally(() => { loading.value = false })
    }

    return {
      allMessagesLoaded,
      blockUser,
      call,
      chooseGiftModal,
      getGifts,
      getImgGift,
      gifts,
      giftModal,
      goMarket,
      hideGiftModal,
      iconChat3,
      iconDiamonds1,
      iconMoreHorizontal,
      iconPhone,
      iconVideoCall,
      loading,
      loadMore,
      messageInputText,
      modalReportUser,
      numberFormat,
      openGifts,
      report,
      reportForm,
      reportUser,
      reportReasonTypes,
      sendGift,
      selectedGift,
      showGiftModal,
      showGifts,
      unBlockUser,
      userGender,
      videoCall,

      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      send,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI + SM Devices
      startConversation,
      mqShallShowLeftSidebar,

      // socket
      socket,
      socketConnecting,
      socketConnected,

      // Message count and time
      messageCount,
      messageTimeLeft,
      messageTimeLeftForHuman,
      messageCountLoading,
    }
  },
}
</script>

<style lang="scss" scoped>

</style>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
.form-send-message {
  border-radius: 100px !important;
  background-color: #EDF0F5;
}
.message-information {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -24px;
  padding: 6px 10px;
  color: #fff;
  background-color: #000;
  .remaining-message {
    display: flex;
    flex-direction: row;
    align-items: center;
    .remaining-message-text {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-left: 12px;
      font-size: 12px;
      .remaining-message-text-title {
        display: block;
        @media screen and (max-width: 767px) {
          display: none;
        }
      }
      p {
        color: #aaa;
        font-size: 10px;
        margin-bottom: 0;
      }
    }
  }
  .market-button {
    @media screen and (max-width: 767px) {
      display: none;
    }
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 12px;
    padding: 6px;
    border-radius: 40px;
    background-color: #393939;
    a {
      color: #fff;
    }
    img {
      margin-right: 4px;
    }
  }
}
</style>
