<template>
  <div
      v-if="typeof contact.profile === 'object'"
      class="user-profile-sidebar"
      :class="{'show': shallShowActiveChatContactSidebar}"
  >
    <!-- Header -->
    <header
        v-if="contact"
        class="user-profile-header"
    >
      <span class="close-icon">
        <feather-icon
            icon="XIcon"
            @click="$emit('update:shall-show-active-chat-contact-sidebar', false)"
        />
      </span>

      <div class="header-profile-sidebar">
        <div class="avatar box-shadow-1 avatar-xl avatar-border">
          <b-avatar
              size="70"
              :src="contact.profile.image"
          />
          <span
              class="avatar-status-xl"
              :class="`avatar-status-${contact.profile.onlineStatus}`"
          />
        </div>
        <h4 class="chat-user-name">
          <router-link
              v-if="!contact.isAssistant"
              :to="{name: 'users.show', params: {id: contact.id}}">
            {{ contact.userName }}
            <span v-if="contact.profile.year">, {{ new Date().getFullYear() - contact.profile.year }}</span>
          </router-link>
          <div v-else>
            {{ contact.userName }}
            <span v-if="contact.profile.year">, {{ new Date().getFullYear() - contact.profile.year }}</span>
          </div>
        </h4>
      </div>
    </header>

    <!-- User Details -->
    <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="user-profile-sidebar-area scroll-area"
    >

      <!-- About -->
      <h6 class="section-label mb-1">
        {{ $t('About') }}
      </h6>
      <p class="fs-12">
        {{ contact.profile.description && contact.profile.description !== ''
          ? contact.profile.description
          : $t('The user has not added information about it.') }}
      </p>

      <!-- Personal Info -->
      <div class="personal-info">
        <h6 class="section-label mb-1 mt-3">
          {{ $t('Personal information') }}
        </h6>
        <ul class="list-unstyled">
          <li class="mb-1">
            <span class="align-middle">
              <b>Yaş :</b>
              {{ contact.profile.year ? new Date().getFullYear() - contact.profile.year : $t('Unknown') }}
            </span>
          </li>
        </ul>
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BAvatar} from 'bootstrap-vue'

export default {
  components: {
    // BSV
    BAvatar,

    // 3rd Party
    VuePerfectScrollbar,
  },
  props: {
    shallShowActiveChatContactSidebar: {
      type: Boolean,
      required: true,
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    return {
      perfectScrollbarSettings,
    }
  },
}
</script>
